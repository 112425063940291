/**
 * Function to set the theme.
 * @since v1.0.0
 * @returns null
 */
const themeSetup = () => {
    const appTheme = localStorage.getItem('theme') || 'light';

      if (
        appTheme === 'dark' &&
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.contains('app-theme')
      ) {
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.remove('bg-secondary-light');
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.add('bg-primary-dark');
      } else {
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.remove('bg-primary-dark');
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.add('bg-secondary-light');
      }
}

const generatePaginationList = (pagination: any) => {
  let list:any = [];
  let current:number = pagination.current_page
  for (let i:number = 1; i <= pagination.last_page; i++) {
    if (i === current) {
      list.push(i);
    } else if (i <= 3 || i > pagination.last_page - 2 || i >= pagination.current_page - 1 && i <= pagination.current_page + 1) {
      list.push(i);
    } else if (i === 4 || i === pagination.last_page - 3) {
      list.push('...');
    }
  }
  list = list.filter((item: any, pos:number) => {
    return list.indexOf(item) === pos;
  });
  return list;
}

const clearCache = async () => {
  // clear local storage all data.
  await localStorage.clear();

  // clear all cache data.
  await caches.keys().then((keyList: any) =>
    Promise.all(
      keyList.map((key: any) => {
          return caches.delete(key);
      })
    )
  )
}

const tabViewHelper = (toShow:any, toHide:any) => {
  let elem1:any = document.getElementById(toShow);
  let elem2:any = document.getElementById(toHide);
  elem1.classList.remove('hidden');
  elem2.classList.add('hidden');
}


export {
    themeSetup,
    clearCache,
    generatePaginationList,
    tabViewHelper,
}