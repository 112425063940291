import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import {themeSetup} from './utils/index';
import VueDragscroll from 'vue-dragscroll'

// Emitter.
import mitt from 'mitt';
const emitter = mitt();

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/css/tailwind.css'

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(createPinia()).use(router).use(VueDragscroll).mount('#app')
themeSetup(); // setup theme.
