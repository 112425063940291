<template>
  <div :class="appTheme">
    <div class="dark:text-white text-gray-800 bg-white dark:bg-gray-700">
      <header
        x-data="{ mobileMenuOpen : false }"
        class="flex flex-wrap flex-row justify-between md:items-center md:space-x-4 py-6 px-6 top-0 z-50 animated shadow-lg dark:bg-gray-900 bg-white relative"
        ref="nav"
      >
        <router-link class="inline-flex" :to="{ path: '/' }">
          <span class="sr-only"></span>
          <img
            class=""
            src="./assets/images/logo.png"
            alt="Logo"
            width="50"
            height="50"
            title="Logo"
          />
          <span class="py-1 font-bold"
            >OpenSource<span class="text-primary-light">Users</span></span
          >
        </router-link>
        <!-- <span class="inline-block mx-auto mr-1 mt-1 md:hidden">
          <ThemeChanger :theme="appTheme"
        /></span> -->

        <button
          @click="mobileMenuOpen = !mobileMenuOpen"
          class="inline-block md:hidden w-8 h-8 p-1"
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        <nav
          class="absolute md:relative top-16 left-0 md:top-0 z-20 md:flex flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto shadow-md rounded-lg md:rounded-none md:shadow-none p-6 pt-0 md:p-0 dark:bg-gray-900 bg-white"
          :class="{ flex: mobileMenuOpen, hidden: !mobileMenuOpen }"
          click="mobileMenuOpen = false"
        >
          <router-link
            to="/"
            class="block py-1 link"
            @click="mobileMenuOpen = false"
            ><i class="fa-sharp fa-solid fa-house"></i> Home</router-link
          >
          <router-link
            to="/peers"
            class="block py-1 link"
            @click="mobileMenuOpen = false"
            ><i class="fa-sharp fa-solid fa-users"></i> Peers</router-link
          >
          <router-link
            to="/groups"
            class="block py-1 link"
            @click="mobileMenuOpen = false"
            ><i class="fa-sharp fa-solid fa-user-group"></i> Groups</router-link
          >
          <router-link
            to="/feed"
            class="block py-1 link"
            @click="mobileMenuOpen = false"
            ><i class="fa-sharp fa-solid fa-signs-post"></i> Posts</router-link
          >
          <router-link
            to="/messages"
            class="block py-1 link"
            @click="mobileMenuOpen = false"
            ><i class="fa-sharp fa-solid fa-envelope"></i>
            <span class="sm:inline hidden md:hidden ml-1"
              >Messages</span
            ></router-link
          >

          <span
            class="block py-1 link cursor-pointer"
            @click="notificationHandler"
            ><i class="fa-sharp fa-solid fa-bell"></i>
            <span class="sm:inline hidden md:hidden ml-1"
              >Notifications</span></span>
          <DropdownComp
            class="py-1"
            icon="fa-sharp fa-solid fa-user link"
            text="Account"
          >
            <router-link
              class="flex px-2 py-2 600 text-sm hover:bg-gray-100"
              to="/profile"
            >
              <i class="fa-sharp fa-solid fa-user mt-1 mx-1"></i>
              Profile
            </router-link>
            <hr />
            <router-link
              class="flex px-2 py-2 600 text-sm hover:bg-gray-100"
              to="/"
            >
              <i class="fa-sharp fa-solid fa-keyboard mt-1 mx-1"></i>
              Shortcuts
            </router-link>
            <router-link
              class="flex px-2 py-2 600 text-sm hover:bg-gray-100"
              to="/"
            >
              <i class="fa-sharp fa-solid fa-gear mt-1 mx-1"></i>
              Settings
            </router-link>
            <router-link
              class="flex px-2 py-2 600 text-red-500 text-sm hover:bg-gray-100"
              to="/"
            >
              <i class="fa-sharp fa-solid fa-right-from-bracket mt-1 mx-1"></i>
              Logout
            </router-link>
          </DropdownComp>
          <!-- <span class="hidden md:inline-block mt-1">
            <ThemeChanger :theme="appTheme"
          /></span> -->
        </nav>
      </header>
       <NotificationComp />
      <div class="">
        <ProgressBar />
      </div>
      <div class="" ref="main">
        <router-view />
      </div>
      <footer class="text-gray-600 body-font">
        <div class="bg-gray-100 dark:text-white dark:bg-gray-900"></div>
      </footer>
    </div>
    <button
      v-if="top"
      class="backtotop bg-gray-200 dark:bg-gray-900 mb-2"
      v-on:click="scrollTop()"
      title="Go to top"
    >
      <i class="text-black dark:text-white fa fa-arrow-up"></i>
    </button>
  </div>
</template>

<script lang="js">
import ThemeChanger from "@/components/ThemeChanger";

import ButtonComp from "@/components/ButtonComp";
import DropdownComp from "@/components/DropdownComp";
import NotificationComp from "@/components/NotificationComp";

import ProgressBar from '@/components/ProgressBar';

// fancybox.
import {
    Fancybox
} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import {
    useThemeStore
} from '@/store/ThemeStore';

export default {
    name: 'app',
    components: {
        //ThemeChanger,
       // ButtonComp,
        ProgressBar,
        DropdownComp,
        NotificationComp,
    },
    watch: {
        $route(to, from) {
            const base = `Muhammad Umer Farooq`
            let title = ""
            if (to.path !== "/") this.path = '/'
            else this.path = ""

            if (typeof to.meta.title === 'string')
                title = `${to.meta.title} - ${base}`
            else if (typeof to.meta.title === 'function')
                title = `${to.meta.title(to)} - ${base}`
            else title = base
            document.title = title
        },
    },
    data() {
        return {
            showModel: false,
            top: false,
            mobileMenuOpen: false,
            hidden: false,
            path: "",
            scroll: 0,
            showNotification: false,
        }
    },
    created() {
        // Fancybox init.
        let fancyboxELems = ["[data-fancybox='default']", "[data-fancybox='certificate']"]
        // for in loop.
        for (let i = 0; i < fancyboxELems.length; i++) {
            Fancybox.bind(fancyboxELems[i], {
                caption: function (fancybox, carousel, slide) {
                    return (
                        slide.caption || ""
                    );
                },
            });
        }

        // setup auto show model.
        if (localStorage.getItem('showModel') === null) {
            localStorage.setItem('showModel', true)
        }
        if (localStorage.getItem('showModel') === 'true') {
            setTimeout(() => {
                this.showModel = true
            }, 5000)
        }

        // back to top button handler.
        window.addEventListener('scroll', this.handleScroll);
    },
    computed: {
        appTheme() {
            return useThemeStore().theme;
        },
    },
    methods: {
         notificationHandler() {
            this.showNotification = !this.showNotification
            this.emitter.emit('notificationHandler', this.showNotification)
        },
        doNotShowModel() {
            this.showModel = false
            localStorage.setItem('showModel', false)
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },
        manageNavScroll(t) {
            let nav = this.$refs.nav
            let main = this.$refs.main
            if (t === 'add') {
                nav.classList.add('fixed')
                nav.classList.add('w-full')
                nav.classList.add('top-0')
                nav.classList.remove('relative')
                main.classList.add('mt-20')
                return;
            }
            nav.classList.remove('fixed')
            nav.classList.remove('w-full')
            nav.classList.remove('top-0')
            nav.classList.add('relative')
            main.classList.remove('mt-20')
        },
        handleScroll(event) {
            let scroll = document.body.scrollTop || document.documentElement.scrollTop;
            if (scroll > 150) {
                if (scroll > 300) {
                    if (this.scroll < scroll) {
                        this.manageNavScroll('remove')
                    } else {
                        this.manageNavScroll('add')
                    }
                }

                this.top = true
            } else {
                this.top = false
                this.manageNavScroll('remove')
            }
            this.scroll = scroll;
        },
    },
}
</script>
