import {RouteRecordRaw} from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: "Home"
    }
  },
  {
    path: '/peers',
    name: 'Peers',
    component: () => import('../views/User/PeersView.vue'),
    meta: {
      title: "Peers"
    }
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import('../views/User/FeedView.vue'),
    meta: {
      title: "Feed"
    }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('../views/User/MessagesView.vue'),
    meta: {
      title: "Messages"
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('../views/User/GroupsView.vue'),
    meta: {
      title: "Groups"
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/User/ProfileView.vue'),
    meta: {
      title: "Profile"
    }
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import('../views/User/GroupView.vue'),
    meta: {
      title: "Group"
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/AuthView.vue'),
    meta: {
      title: "Auth"
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/ResetView.vue'),
    meta: {
      title: "Reset"
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      title: "ResetPassword"
    }
  },
];

export default routes;
