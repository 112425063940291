<template>
  <div>
    <button
      class="bg-primary-light rounded-primary hover:bg-black font-bold text-white py-2 px-4"
      :disabled="disable"
    >
      {{ text }}
    </button>
  </div>
</template>

<script lang="js">
export default {
    name: "ButtonComp",
    props: {
        text: {
            type: String,
            default: "Button"
        },
        disable: {
          type: Boolean,
          default: false
        }
    }
}
</script>
